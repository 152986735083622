import _d3Path from "d3-path";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-chord/ v2.0.0 Copyright 2020 Mike Bostock
(function (global, factory) {
  factory(exports, _d3Path);
})(exports, function (exports, d3Path) {
  'use strict';

  var abs = Math.abs;
  var cos = Math.cos;
  var sin = Math.sin;
  var pi = Math.PI;
  var halfPi = pi / 2;
  var tau = pi * 2;
  var max = Math.max;
  var epsilon = 1e-12;

  function range(i, j) {
    return Array.from({
      length: j - i
    }, (_, k) => i + k);
  }

  function compareValue(compare) {
    return function (a, b) {
      return compare(a.source.value + a.target.value, b.source.value + b.target.value);
    };
  }

  function chord() {
    return chord$1(false, false);
  }

  function chordTranspose() {
    return chord$1(false, true);
  }

  function chordDirected() {
    return chord$1(true, false);
  }

  function chord$1(directed, transpose) {
    var padAngle = 0,
        sortGroups = null,
        sortSubgroups = null,
        sortChords = null;

    function chord(matrix) {
      var n = matrix.length,
          groupSums = new Array(n),
          groupIndex = range(0, n),
          chords = new Array(n * n),
          groups = new Array(n),
          k = 0,
          dx;
      matrix = Float64Array.from({
        length: n * n
      }, transpose ? (_, i) => matrix[i % n][i / n | 0] : (_, i) => matrix[i / n | 0][i % n]); // Compute the scaling factor from value to angle in [0, 2pi].

      for (let i = 0; i < n; ++i) {
        let x = 0;

        for (let j = 0; j < n; ++j) x += matrix[i * n + j] + directed * matrix[j * n + i];

        k += groupSums[i] = x;
      }

      k = max(0, tau - padAngle * n) / k;
      dx = k ? padAngle : tau / n; // Compute the angles for each group and constituent chord.

      {
        let x = 0;
        if (sortGroups) groupIndex.sort((a, b) => sortGroups(groupSums[a], groupSums[b]));

        for (const i of groupIndex) {
          const x0 = x;

          if (directed) {
            const subgroupIndex = range(~n + 1, n).filter(j => j < 0 ? matrix[~j * n + i] : matrix[i * n + j]);
            if (sortSubgroups) subgroupIndex.sort((a, b) => sortSubgroups(a < 0 ? -matrix[~a * n + i] : matrix[i * n + a], b < 0 ? -matrix[~b * n + i] : matrix[i * n + b]));

            for (const j of subgroupIndex) {
              if (j < 0) {
                const chord = chords[~j * n + i] || (chords[~j * n + i] = {
                  source: null,
                  target: null
                });
                chord.target = {
                  index: i,
                  startAngle: x,
                  endAngle: x += matrix[~j * n + i] * k,
                  value: matrix[~j * n + i]
                };
              } else {
                const chord = chords[i * n + j] || (chords[i * n + j] = {
                  source: null,
                  target: null
                });
                chord.source = {
                  index: i,
                  startAngle: x,
                  endAngle: x += matrix[i * n + j] * k,
                  value: matrix[i * n + j]
                };
              }
            }

            groups[i] = {
              index: i,
              startAngle: x0,
              endAngle: x,
              value: groupSums[i]
            };
          } else {
            const subgroupIndex = range(0, n).filter(j => matrix[i * n + j] || matrix[j * n + i]);
            if (sortSubgroups) subgroupIndex.sort((a, b) => sortSubgroups(matrix[i * n + a], matrix[i * n + b]));

            for (const j of subgroupIndex) {
              let chord;

              if (i < j) {
                chord = chords[i * n + j] || (chords[i * n + j] = {
                  source: null,
                  target: null
                });
                chord.source = {
                  index: i,
                  startAngle: x,
                  endAngle: x += matrix[i * n + j] * k,
                  value: matrix[i * n + j]
                };
              } else {
                chord = chords[j * n + i] || (chords[j * n + i] = {
                  source: null,
                  target: null
                });
                chord.target = {
                  index: i,
                  startAngle: x,
                  endAngle: x += matrix[i * n + j] * k,
                  value: matrix[i * n + j]
                };
                if (i === j) chord.source = chord.target;
              }

              if (chord.source && chord.target && chord.source.value < chord.target.value) {
                const source = chord.source;
                chord.source = chord.target;
                chord.target = source;
              }
            }

            groups[i] = {
              index: i,
              startAngle: x0,
              endAngle: x,
              value: groupSums[i]
            };
          }

          x += dx;
        }
      } // Remove empty chords.

      chords = Object.values(chords);
      chords.groups = groups;
      return sortChords ? chords.sort(sortChords) : chords;
    }

    chord.padAngle = function (_) {
      return arguments.length ? (padAngle = max(0, _), chord) : padAngle;
    };

    chord.sortGroups = function (_) {
      return arguments.length ? (sortGroups = _, chord) : sortGroups;
    };

    chord.sortSubgroups = function (_) {
      return arguments.length ? (sortSubgroups = _, chord) : sortSubgroups;
    };

    chord.sortChords = function (_) {
      return arguments.length ? (_ == null ? sortChords = null : (sortChords = compareValue(_))._ = _, chord) : sortChords && sortChords._;
    };

    return chord;
  }

  var slice = Array.prototype.slice;

  function constant(x) {
    return function () {
      return x;
    };
  }

  function defaultSource(d) {
    return d.source;
  }

  function defaultTarget(d) {
    return d.target;
  }

  function defaultRadius(d) {
    return d.radius;
  }

  function defaultStartAngle(d) {
    return d.startAngle;
  }

  function defaultEndAngle(d) {
    return d.endAngle;
  }

  function defaultPadAngle() {
    return 0;
  }

  function defaultArrowheadRadius() {
    return 10;
  }

  function ribbon(headRadius) {
    var source = defaultSource,
        target = defaultTarget,
        sourceRadius = defaultRadius,
        targetRadius = defaultRadius,
        startAngle = defaultStartAngle,
        endAngle = defaultEndAngle,
        padAngle = defaultPadAngle,
        context = null;

    function ribbon() {
      var buffer,
          s = source.apply(this || _global, arguments),
          t = target.apply(this || _global, arguments),
          ap = padAngle.apply(this || _global, arguments) / 2,
          argv = slice.call(arguments),
          sr = +sourceRadius.apply(this || _global, (argv[0] = s, argv)),
          sa0 = startAngle.apply(this || _global, argv) - halfPi,
          sa1 = endAngle.apply(this || _global, argv) - halfPi,
          tr = +targetRadius.apply(this || _global, (argv[0] = t, argv)),
          ta0 = startAngle.apply(this || _global, argv) - halfPi,
          ta1 = endAngle.apply(this || _global, argv) - halfPi;
      if (!context) context = buffer = d3Path.path();

      if (ap > epsilon) {
        if (abs(sa1 - sa0) > ap * 2 + epsilon) sa1 > sa0 ? (sa0 += ap, sa1 -= ap) : (sa0 -= ap, sa1 += ap);else sa0 = sa1 = (sa0 + sa1) / 2;
        if (abs(ta1 - ta0) > ap * 2 + epsilon) ta1 > ta0 ? (ta0 += ap, ta1 -= ap) : (ta0 -= ap, ta1 += ap);else ta0 = ta1 = (ta0 + ta1) / 2;
      }

      context.moveTo(sr * cos(sa0), sr * sin(sa0));
      context.arc(0, 0, sr, sa0, sa1);

      if (sa0 !== ta0 || sa1 !== ta1) {
        if (headRadius) {
          var hr = +headRadius.apply(this || _global, arguments),
              tr2 = tr - hr,
              ta2 = (ta0 + ta1) / 2;
          context.quadraticCurveTo(0, 0, tr2 * cos(ta0), tr2 * sin(ta0));
          context.lineTo(tr * cos(ta2), tr * sin(ta2));
          context.lineTo(tr2 * cos(ta1), tr2 * sin(ta1));
        } else {
          context.quadraticCurveTo(0, 0, tr * cos(ta0), tr * sin(ta0));
          context.arc(0, 0, tr, ta0, ta1);
        }
      }

      context.quadraticCurveTo(0, 0, sr * cos(sa0), sr * sin(sa0));
      context.closePath();
      if (buffer) return context = null, buffer + "" || null;
    }

    if (headRadius) ribbon.headRadius = function (_) {
      return arguments.length ? (headRadius = typeof _ === "function" ? _ : constant(+_), ribbon) : headRadius;
    };

    ribbon.radius = function (_) {
      return arguments.length ? (sourceRadius = targetRadius = typeof _ === "function" ? _ : constant(+_), ribbon) : sourceRadius;
    };

    ribbon.sourceRadius = function (_) {
      return arguments.length ? (sourceRadius = typeof _ === "function" ? _ : constant(+_), ribbon) : sourceRadius;
    };

    ribbon.targetRadius = function (_) {
      return arguments.length ? (targetRadius = typeof _ === "function" ? _ : constant(+_), ribbon) : targetRadius;
    };

    ribbon.startAngle = function (_) {
      return arguments.length ? (startAngle = typeof _ === "function" ? _ : constant(+_), ribbon) : startAngle;
    };

    ribbon.endAngle = function (_) {
      return arguments.length ? (endAngle = typeof _ === "function" ? _ : constant(+_), ribbon) : endAngle;
    };

    ribbon.padAngle = function (_) {
      return arguments.length ? (padAngle = typeof _ === "function" ? _ : constant(+_), ribbon) : padAngle;
    };

    ribbon.source = function (_) {
      return arguments.length ? (source = _, ribbon) : source;
    };

    ribbon.target = function (_) {
      return arguments.length ? (target = _, ribbon) : target;
    };

    ribbon.context = function (_) {
      return arguments.length ? (context = _ == null ? null : _, ribbon) : context;
    };

    return ribbon;
  }

  function ribbon$1() {
    return ribbon();
  }

  function ribbonArrow() {
    return ribbon(defaultArrowheadRadius);
  }

  exports.chord = chord;
  exports.chordDirected = chordDirected;
  exports.chordTranspose = chordTranspose;
  exports.ribbon = ribbon$1;
  exports.ribbonArrow = ribbonArrow;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const chord = exports.chord,
      chordDirected = exports.chordDirected,
      chordTranspose = exports.chordTranspose,
      ribbon = exports.ribbon,
      ribbonArrow = exports.ribbonArrow,
      __esModule = exports.__esModule;